import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';
import { commonHeaderColor } from '../../../util';


const Page = ({ data }) => (
  <>
    <SEO
      title="Holen Sie sich Ihr NeoSurf-Gutschein schnell und sicher"
      description="Mit Neosurf können Sie problemlos einkaufen, spielen oder sich bei Ihren Lieblingsdiensten anmelden."
      alternateLanguagePages={[
        {
          path: 'buy-neosurf',
          language: 'en'
        },
        {
          path: 'acheter-neosurf',
          language: 'fr'
        },
        {
          path: 'buy-neosurf',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://bit.ly/2AlU6Ch">
            <Img fluid={data.myneosurf.childImageSharp.fluid} alt="MyNeoSurf Logo" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">Kaufen Sie Neosurf</h1>
            <p className="textColorDark">Holen Sie sich Ihr NeoSurf-Gutschein schnell und sicher!</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <p className="textColorDark">Neosurf ist eine der bequemsten und sichersten Methoden, um online Zahlungen zu tätigen. Sie können Neosurf nutzen, um einzukaufen, zu spielen oder sich für Ihre Lieblingsdienste anzumelden – ganz ohne Komplikationen. Es handelt sich um eine unkomplizierte, bargeldbasierte Zahlungsmethode, die Ihre persönlichen Informationen schützt und Ihnen somit ein sicheres Einkaufserlebnis ermöglicht.</p>
      <p className="textColorDark">Der Kauf eines Neosurf-Gutscheins ist sehr einfach. Sie können Neosurf an autorisierten Verkaufsstellen kaufen, wie in örtlichen Kiosken, Supermärkten, Tankstellen und anderen Händlern. Die Gutscheine sind in verschiedenen Werten erhältlich, sodass Sie problemlos einen Betrag wählen können, der Ihren Bedürfnissen entspricht. Diese Flexibilität macht Neosurf zu einer hervorragenden Wahl für Benutzer, die ihre Ausgaben effektiv verwalten möchten. Wenn Sie beispielsweise ein Budget einhalten möchten, können Sie Neosurf mit einem geplanten Betrag kaufen, was das Risiko von Überschreitungen verringert.</p>
      <p className="textColorDark">Datenschutz ist einer der größten Vorteile von Neosurf, da es im Gegensatz zu traditionellen Zahlungsmethoden wie Kreditkarten nicht erforderlich ist, sensible persönliche oder finanzielle Informationen zu teilen. Dies ist die ideale Wahl für diejenigen, die Sicherheit und Anonymität bei Online-Transaktionen bevorzugen.</p>
      <p className="textColorDark">Neosurf wird weltweit auf Tausenden von Online-Plattformen akzeptiert, darunter Gaming-Websites, E-Commerce-Shops, Abonnementdienste und mehr. Gamer lieben Neosurf, weil es schnelle und einfache Aufladungen für Spielkonten ohne Verzögerung ermöglicht, weshalb es auch perfekt für Casinos ist.</p>
      <p className="textColorDark">Neosurf ist besonders vorteilhaft für diejenigen, die keinen Zugang zu traditionellen Bankdienstleistungen oder Kreditkarten haben. Mit Neosurf können Sie Online-Dienste bezahlen, ohne ein Bankkonto zu benötigen. Für diejenigen, die Online-Transaktionen lieber mit Bargeld durchführen, ist Neosurf die ideale Lösung.</p>
      <p className="textColorDark">Wenn Sie bereit sind, einen Neosurf-Gutschein zu kaufen, beginnen Sie damit, eine vertrauenswürdige Verkaufsstelle in Ihrer Nähe oder einen verifizierten Online-Händler zu finden. Nach dem Erwerb des Gutscheins ist die Verwendung sehr einfach. Alles, was Sie tun müssen, ist, den Code des Gutscheins auf jeder Plattform einzugeben, die Neosurf akzeptiert. Es gibt keine versteckten Gebühren oder komplizierten Verfahren, was es zu einer unkomplizierten Zahlungsmethode macht.</p>
      <p className="textColorDark">Neosurf ist weltweit zu einer beliebten Wahl geworden, weil es einfach und zuverlässig ist. Wenn Sie nach einer sicheren und einfachen Möglichkeit suchen, online einzukaufen, wird Ihnen ein Neosurf-Gutschein dieses Erlebnis bieten. Holen Sie sich jetzt einen Neosurf-Gutschein!</p>
    </ContentRow>
  </>
)

export default Page;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
